import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { DefaultTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faUsers,
  faGraduationCap,
  faMicrophone,
  faClock,
  faStarCircle,
} from "@fortawesome/pro-light-svg-icons";

import { Title, Section, Box, Text } from "../../components/Core";
import { ColorTypes } from "../../utils";

interface FeatureCardProps {
  color?: string;
  icon?: any;
  title?: string;
}

interface ThemeProps {
  theme: DefaultTheme;
  color: keyof ColorTypes;
  children: React.ReactNode;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  color = "primary",
  icon,
  title,
  children,
  ...rest
}) => (
  <Box pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center mx-auto"
      css={`
        background-color: ${({ theme, color }: ThemeProps) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      <FontAwesomeIcon icon={icon} />
    </Box>
    <div className="text-center">
      <Text
        color="heading"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative" mt={-3}>
      <Container>
        <Row className="mb-0 text-center">
          <Col lg="12">
            <Title>Designed for connecting teams</Title>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faLightbulb}
              title="Knowledge Sharing"
            >
              Colleagues can share knowledge instantly with each other to help
              customers faster.
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faUsers}
              title="Improved Team Morale"
            >
              Colleagues feel safer and happier at work with VoCoVo.
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faGraduationCap}
              title="Training and Onboarding"
            >
              New starters can be mentored remotely, ask questions and learn as
              they go.
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faMicrophone}
              title="Remote Broadcasting"
            >
              Speed up store-to-store communication and send updates from Head
              Office apps.
            </FeatureCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faClock}
              title="Efficient Colleagues"
            >
              When colleagues don’t have to search the shop floor for answers,
              they can get on with task at hand.
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              icon={faStarCircle}
              title="Customer Experience"
            >
              Customers get faster responses, shorter queues and the best of
              your colleagues.
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;
