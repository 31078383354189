import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import pdfBrochure from "../../assets/downloads/ShopSafe-ShopWatch-and-TownLink-Brochure.pdf";

interface ThemeProps {
  theme: DefaultTheme;
}

const ContentImg = styled(Box)<ThemeProps>`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="6">
            <Title variant="hero">
              Empowering retail teams to collaborate.
            </Title>
          </Col>

          <Col lg="6" className="pl-lg-5 pt-lg-4">
            <Text>
              Our in-store and on-site radio systems are designed for shops and
              bars. Affordable and license free, connecting your staff with
              instant group communication. Boost productivity and safety.
            </Text>
            <a href={pdfBrochure} download>
              <Button mt={4} variant="solid" color="light" mr={3}>
                Download Our Brochure
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;
